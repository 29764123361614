/* globals siteurl, currency */

import cssVars from 'css-vars-ponyfill';
import 'selectize';
import 'featherlight';
import 'lazysizes';

cssVars();
const productionVideoEmbedCode = '<iframe src="https://player.vimeo.com/video/110784527?color=8ec000" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>';
const productionVideoSmallEmbedCode = '<iframe src="//player.vimeo.com/video/110784527" width="400" height="225" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
const productionVideoBigEmbedCode = '<iframe src="//player.vimeo.com/video/110784527" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
const howitworksVideoId = { en: '38226326', de: '36968696' };
const howitworksVideoEmbedCode = `<iframe src="//player.vimeo.com/video/${howitworksVideoId[lang]} width="853" height="480" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

const $navContainer = $('.nav-container');
const smartYoutubeEmbed = function () {
  function labnolThumb(id) {
    return `<img class="youtube-thumb" src="//i.ytimg.com/vi/${id}/maxresdefault.jpg"><div class="play-button"></div>`;
  }

  function labnolIframe() {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `//www.youtube.com/embed/${this.parentNode.dataset.id}?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=1&showinfo=1`);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('id', 'youtube-iframe');
    this.parentNode.replaceChild(iframe, this);
  }

  const v = document.getElementsByClassName('youtube-player');
  for (let n = 0; n < v.length; n++) {
    const p = document.createElement('div');
    p.innerHTML = labnolThumb(v[n].dataset.id);
    p.onclick = labnolIframe;
    v[n].appendChild(p);
  }
};
const formatPrice = function (price) {
  price = parseFloat(price);
  price = price.toFixed(2);
  if (currency === 'USD') {
    price = `$${price}`;
  } else if (currency === 'EUR') {
    price = `${price} €`;
  } else if (currency === 'CHF') {
    price = `${price} CHF`;
  } else {
    price = `${price} €`;
  }
  if (currency === 'EUR' || currency === 'CHF') {
    price = price.replace('.', ',');
  }
  return price;
};
const formatNumberWithDots = function(number) {
  // 1234567 becomes 1.234.567
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
const get_currency_symbol = function () {
  return $('#page-data').data('current-currency-symbol');
};
const IsEmail = function (email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
};
const close_newsletter_popup = function () {
  $.post(
    `${siteurl}ajax/close_newsletter_popup`,
    function () {
      $('#newsletter-popup').hide();
      $('#newsletter-bodyclick').hide();
      $('#close-newsletter-popup').hide();
    },
  );
};
const subscribe_to_newsletter = function (email, from) {
  if (IsEmail(email)) {
    $.post(
      `${siteurl}ajax/subscribe_to_newsletter`,
      { email, from },
      function (data) {
        if (from === 'popup') {
          $('#newsletter-popup').html(data.message);
          setTimeout(function () {
            $('#newsletter-bodyclick').fadeOut();
            $('#close-newsletter-popup').fadeOut();
            $('#newsletter-popup').fadeOut();
          }, 10000);
        }
        if (from === 'header-bar' || from === 'homepage-box') {
          $.featherlight('<p>' + data.message + '</p>');
        }
        if (from === 'bottom-bar') {
          $('.subscribe-to-newsletter-form').html(data.message).delay(10000).fadeOut();
        }
        if (from === 'Kleinehelden') {
          $('.form-kleinehelden').html(data.message);
        }
        if (from === 'newsletter_page') {
          $('.form-newsletter').html(data.message);
        }
      }, 'json',
    );
  }
  else {
    $('#subscribe-to-newsletter-email-' + from)
      .val('').addClass('error')
      .attr('placeholder', $('.subscribe-to-newsletter-button').data('error-message'));
  }
};
const openLoginPopup = function () {
  $.featherlight('#login-and-register-popup');
};
const embedVideo = function () {
  $(this).parent().html('<iframe src="https://player.vimeo.com/video/' + $(this).data('video-id') + '?portrait=0&title=0&color=f78800&badge=0&byline=0&autoplay=1" frameborder="0"  webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>');
};

const clog = function(reason, input_data) {
  $.post(
    siteurl + 'ajax/clog',
    {r: reason, d: input_data}
  );
}
/*
 * These are callback to be used with featherlight, but since
 * Webpack I couldn't make them work anymore and they are now declared
 * in the layout file as well.
 */
const featherlightAfterOpen = function () {
  $('html').addClass('u-no-scroll');
};
const featherlightAfterClose = function () {
  $('html').removeClass('u-no-scroll');
};

const scrollToTop = function() {
  window.scroll({top: 0, left: 0, behavior: 'smooth'});
}

const roundNumber = function (num, dec) {
  let result = String(Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec));
  if (result.indexOf('.') < 0) {
    result += '.';
  }
  while (result.length - result.indexOf('.') <= dec) {
    result += '0';
  }
  result = result.replace('.', ',');

  if (result.length === 7) {
    let pre_point = result[0];
    let post_point = '';
    for (let k = 1; k <= 6; k += 1) {
      post_point = post_point + result[k];
    }
    result = pre_point + '.' + post_point;
  }
  else if (result.length === 8) {
    let pre_point = result[0] + result[1];
    let post_point = '';
    for (let k = 2; k <= 7; k++) {
      post_point = post_point + result[k];
    }
    result = pre_point + '.' + post_point;
  }
  return result;
};

smartYoutubeEmbed();
$('.video-embed-js').click(embedVideo);
// in the about us page
$('#join-our-community-button').click(openLoginPopup);
// Check the initial Poistion of the Sticky Header



$(window).on('load', () => {

  if(is_american_website == true) {
    
    /*
    $.get(
      `${siteurl}ajax/check_country`,
      function (data) {
        if(data.success == false 
          || (data.success == true && data.country_code != 'US')) {

            $("#country-not-available-for-shipping-popup").show();
            $("#bodyclick").show();
            if(data.success == false)
              $(".country-not-available-message").show();
            else
              $(".switch-to-europe-message").show();
        }
      },
      'json'
    );
    */
   
  }

  $(".close-popup-js").click(function() {
    $(".popup").fadeOut('fast');
    $("#bodyclick").fadeOut('fast');
  })
});

if ($navContainer.length) {
  var stickyHeaderTop = $navContainer.offset().top;

  $(window).scroll(function () {
    if ($(window).scrollTop() > stickyHeaderTop) {
      $navContainer.addClass('fixed');
    } else {
      $navContainer.removeClass('fixed');
    }
  });
}

$('.number-increaser').each(function () {
  $(this).prop('Counter',0).animate({
    Counter: $(this).text()
  }, {
    duration: 1000,
    easing: 'swing',
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
});

$('.top-strip-close-button').click(function () {
  $('.top-strip').hide();
  $.post(
    siteurl + 'ajax/hide_top_offer_bar',
  );
});


if ($('.embed-container').length) {
  $(window).bind('load', function () {
    $('.embed-container').html(productionVideoEmbedCode);
  });
}

if ($('.how-it-works-video-popup').length) {
  $(window).bind('load', function () {
    $('.how-it-works-video-popup').html(howitworksVideoEmbedCode);
  });
}

if ($('.production-video').length) {
  $(window).bind('load', function () {
    $('.production-video').html(productionVideoSmallEmbedCode);
  });
}

if ($('.print-production-video').length) {
  $(window).bind('load', function () {
    $('.print-production-video').html(productionVideoBigEmbedCode);
  });
}

$('.subscribe-to-newsletter-button').click(function () {
  var from = $(this).data('from');
  subscribe_to_newsletter($('#subscribe-to-newsletter-email-' + from).val(), from);
});

$('.homepage-box').on('click', '.subscribe-to-newsletter-button', function () {
  var from = $(this).data('from');
  subscribe_to_newsletter($('#subscribe-to-newsletter-email-' + from).val(), from);
});

const mockupInteraction = function () {
  $(".c-card__mockup").on('mouseenter', function() {
    $(this).css('background-image', 'url(' + $(this).data('src') + ')' );
  })
  $(".c-card__mockup").on('mouseleave', function() {
    $(this).css('background-image', 'none' );
  })
};
mockupInteraction();

export {
  clog,
  scrollToTop,
  featherlightAfterOpen,
  featherlightAfterClose,
  smartYoutubeEmbed,
  IsEmail,
  get_currency_symbol,
  formatPrice,
  roundNumber,
  formatNumberWithDots,
  mockupInteraction
};


